<template>
    <div class="card card-outline card-primary">
        <div class="card-header">
            <h3 class="card-title">创建接口</h3>
            <div class="card-tools">
                <!-- Buttons, labels, and many other things can be placed here! -->
                <!-- Here is a label for example -->
                <span class="badge badge-primary">Label</span>
            </div>
            <!-- /.card-tools -->
        </div>
        <!-- /.card-header -->
        <div class="card-body">
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">接口信息</h3>
                    <div class="card-tools">
                        <!-- Buttons, labels, and many other things can be placed here! -->
                        <!-- Here is a label for example -->
                        <button class="btn btn-outline btn-light">
                            <i class="fas fa-add"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">接口名</span>
                                <input type="text" class="form-control" v-model="form_data.name" placeholder="接口名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">接口url</span>
                                <input type="text" class="form-control" v-model="form_data.url" placeholder="接口url">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">请求类型</span>
                                <input type="text" class="form-control" v-model="form_data.method" placeholder="请求类型">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">接口描述</span>
                                <input type="text" class="form-control" v-model="form_data.desc" placeholder="接口描述">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">接口logo</span>
                                <input type="text" class="form-control" v-model="form_data.icon" placeholder="接口logo">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /.card-body -->
            </div>

            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">输入参数</h3>
                    <div class="card-tools">
                        <!-- Buttons, labels, and many other things can be placed here! -->
                        <!-- Here is a label for example -->
                        <button class="btn btn-outline btn-light" @click="addInput">
                            <i class="fas fa-add"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-2" v-for="item in form_data.params" :key="item.id">
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">参数{{ item.id }}</span>
                                <input type="text" class="form-control" v-model="item.name" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">参数描述</span>
                                <input type="text" class="form-control" v-model="item.desc" placeholder="参数描述">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">参数类型</span>
                                <input type="text" class="form-control" v-model="item.type" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">默认值</span>
                                <input type="text" class="form-control" v-model="item.default" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">校验规则</span>
                                <input type="text" class="form-control" v-model="item.rules" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col-1">
                            <button class="btn btn-outline btn-danger" @click="removeInput(item.id)">
                                <i class="fas fa-add"></i>
                            </button>
                        </div>

                    </div>
                </div>
                <!-- /.card-body -->
            </div>
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">编辑脚本</h3>
                    <div class="card-tools">
                        <!-- Buttons, labels, and many other things can be placed here! -->
                        <!-- Here is a label for example -->
                        <!-- <button class="btn btn-outline btn-light" @click="addOutput">
                            <i class="fas fa-add"></i>
                        </button> -->
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-2">
                        <div class="col-12">
                            <CodeMirrorEditer @input="input" @save="save" :content="form_data.script" />
                        </div>

                    </div>
                </div>
                <!-- /.card-body -->
            </div>

            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">输出参数</h3>
                    <div class="card-tools">
                        <!-- Buttons, labels, and many other things can be placed here! -->
                        <!-- Here is a label for example -->
                        <button class="btn btn-outline btn-light" @click="addOutput">
                            <i class="fas fa-add"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row mb-2" v-for="item in form_data.response" :key="item.id">
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">参数{{ item.id }}</span>
                                <input type="text" class="form-control" v-model="item.name" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">参数描述</span>
                                <input type="text" class="form-control" v-model="item.desc" placeholder="参数描述">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">参数类型</span>
                                <input type="text" class="form-control" v-model="item.type" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">默认值</span>
                                <input type="text" class="form-control" v-model="item.default" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-group mb-3">
                                <span class="input-group-text">校验规则</span>
                                <input type="text" class="form-control" v-model="item.rules" placeholder="参数名">
                            </div>
                        </div>
                        <div class="col-1">
                            <button class="btn btn-outline btn-danger" @click="removeOutput(item.id)">
                                <i class="fas fa-add"></i>
                            </button>
                        </div>

                    </div>
                </div>
                <!-- /.card-body -->
            </div>




        </div>
        <!-- /.card-body -->
        <div class="card-footer">
            <button class="btn btn-outline btn-danger" @click="submit">
                <i class="fas fa-save"></i>
            </button>
        </div>
        <!-- /.card-footer -->
    </div>
    <!-- /.card -->
</template>

<script>
import CodeMirrorEditer from '@/components/editer/Editer'
import { createApi, getApiInfo } from '@/requests/api'
export default {
    name: "",
    data() {
        return {
            editor: null,
            form_data: {
                params: [
                    {
                        id: 1,
                        name: "",
                        desc: "",
                        type: "",
                        default: "",
                        rules: ""
                    }
                ],
                response: [
                    {
                        id: 1,
                        name: "",
                        desc: "",
                        type: "",
                        default: "",
                        rules: ""
                    }
                ],
                script: '',
                method: '',
                url: '',
                name: '',
                desc: '',
                icon: ''
            }
        };
    },
    components: {
        CodeMirrorEditer
    },
    mounted() {
        let id = this.$route.query.id
        if (id) {
            this.getOneInfo(id)
        }
    },
    methods: {
        getOneInfo(id) {
            getApiInfo({ id: id }).then((res) => {
                let data = res.data
                data.params = JSON.parse(res.data.params).params
                data.response = JSON.parse(res.data.response).params
                console.log(data)
                delete data.create_time
                delete data.update_time
                this.form_data = data
            })
        },
        save(text) {
            console.log(text)
            console.log('save')
            return true
        },
        input(text) {
            console.log(text)
        },
        findMaxId(list) {
            if (list.length == 0) {
                return 0;
            }
            return list[list.length - 1].id
        },
        addInput() {
            this.form_data.params.push(
                {
                    id: this.findMaxId(this.form_data.params) + 1,
                    name: "",
                    desc: "",
                    type: "",
                    default: "",
                    filter: ""
                }
            )
        },
        removeInput(id) {
            console.log(id)
            this.form_data.params.splice(id - 1, 1)
            console.log(this.form_data)
        },
        addOutput() {
            this.form_data.response.push(
                {
                    id: this.findMaxId(this.form_data.response) + 1,
                    name: "",
                    desc: "",
                    type: "",
                    default: "",
                    filter: ""
                }
            )
        },
        removeOutput(id) {
            console.log(id)
            this.form_data.response.splice(id - 1, 1)
            console.log(this.form_data)
        },
        submit() {
            createApi(this.form_data).then((res) => {
                console.log(res)
                this.$XModal.message({
                    content: "添加成功",
                    status: "success",
                });
            })
        }
    },

}
</script>

<style scoped></style>