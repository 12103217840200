<template>
  <div class="editor-container" ref="editorContainer"></div>
</template>
  
<script>
import { EditorView, keymap } from "@codemirror/view"
import { insertTab, indentLess, defaultKeymap } from "@codemirror/commands"
import { EditorState } from "@codemirror/state"
import { basicSetup } from "@uiw/codemirror-extensions-basic-setup"
import { sublime } from "@uiw/codemirror-theme-sublime"
import { javascript } from '@codemirror/lang-javascript';
import { php } from '@codemirror/lang-php';
import { python } from '@codemirror/lang-python';


export default {
  name: 'CodeMirrorEditor',
  props: {
    content: {
      type: String,
      default: '请输入代码逻辑',
    },
  },
  mounted() {
    const states = EditorState.create({
      doc: this.content,
      extensions: [
        basicSetup({
          tabSize: 4,
        }),
        sublime,
        javascript(),
        php(),
        python(),
        keymap.of(
          [
            defaultKeymap,
            {
              key: 'Tab',
              preventDefault: true,
              run: insertTab,
            },
            {
              key: 'Shift-Tab',
              preventDefault: true,
              run: indentLess,
            },
            {
              key: 'Ctrl-s',
              preventDefault: true,
              run: this.save
            }
          ]
        ),
        EditorView.updateListener.of((v) => {
          this.$emit('input', v.state.doc.toString());
        })
      ],
    });
    new EditorView({
      state: states,
      parent: this.$refs.editorContainer,
    });

  },
  methods: {
    save(instance) {
      this.$emit('save', instance.viewState.state.doc.toString());
    }
  }
};
</script>
  